import pharmArrowAnimation from 'pages/customProcess/components/WBA/assets/arrow-animation-pharm.gif'
import BootsLogo from 'pages/customProcess/components/WBA/assets/logo-boots.svg'
import N7Logo from 'pages/customProcess/components/WBA/assets/logo-n7-beauty.svg'
import PharmLogo from 'pages/customProcess/components/WBA/assets/logo-pharm.svg'
import { PhasesDefaultContent } from 'pages/customProcess/components/WBA/PhasesDefaultContent'
import { Content, Phases } from 'pages/customProcess/components/WBA/types'

export const HubsContent: Content = {
  pharm: {
    colors: {
      '--bg-top': '#FFFBDB',
      '--bg-left': '#E6EEFF',
      '--bg-right': '#FFE6E8',

      '--header-primary-color': '#001F61',
      '--header-secondary-color': '#F6E302',
      '--header-tertiary-color': '#C7B800',
      '--header-quaternary-color': '#00277A',
      '--header-quinary-color': '#013FC2',
      '--header-senary-color': '#013FC2',
      '--header-septenary-color': '#990011',
      '--header-octonary-color': '#E5283D',

      '--phase-index-color': '#000000',
      '--phase-index-active-color': '#1c1c1c',
      '--brief-bg-color': '#fffabb',
      '--brief-border-color': '#fefdf2',
      '--brief-border-active-color': '#f6e302',
      '--brief-bg-active-color': '#ffffff',
      '--strategy-bg-color': '#ebebeb',
      '--strategy-border-color': '#ffffff',
      '--strategy-bg-active-color': '#ffffff',
      '--strategy-border-active-color': '#3b3b3b',
      '--idea-bg-color': '#dce7ff',
      '--idea-border-color': '#f7f9fb',
      '--idea-bg-active-color': '#ffffff',
      '--idea-border-active-color': '#013fc2',
      '--design-bg-color': '#ffd8dd',
      '--design-border-color': '#fef4f5',
      '--design-bg-active-color': '#ffffff',
      '--design-border-active-color': '#e5283d',
    },
    logo: PharmLogo,
    animation: pharmArrowAnimation,
    phases: PhasesDefaultContent,
  },
  no7Beauty: {
    colors: {
      '--bg-top': '#FFFBDB',
      '--bg-left': '#E6EEFF',
      '--bg-right': '#FFE6E8',

      '--header-primary-color': '#001F61',
      '--header-secondary-color': '#F6E302',
      '--header-tertiary-color': '#C7B800',
      '--header-quaternary-color': '#00277A',
      '--header-quinary-color': '#013FC2',
      '--header-senary-color': '#013FC2',
      '--header-septenary-color': '#990011',
      '--header-octonary-color': '#E5283D',

      '--phase-index-color': '#000000',
      '--phase-index-active-color': '#1c1c1c',
      '--brief-bg-color': '#fffabb',
      '--brief-border-color': '#fefdf2',
      '--brief-border-active-color': '#f6e302',
      '--brief-bg-active-color': '#ffffff',
      '--strategy-bg-color': '#ebebeb',
      '--strategy-border-color': '#ffffff',
      '--strategy-bg-active-color': '#ffffff',
      '--strategy-border-active-color': '#3b3b3b',
      '--idea-bg-color': '#dce7ff',
      '--idea-border-color': '#f7f9fb',
      '--idea-bg-active-color': '#ffffff',
      '--idea-border-active-color': '#013fc2',
      '--design-bg-color': '#ffd8dd',
      '--design-border-color': '#fef4f5',
      '--design-bg-active-color': '#ffffff',
      '--design-border-active-color': '#e5283d',
    },
    logo: N7Logo,
    animation: pharmArrowAnimation,
    phases: {
      [Phases.Brief]: {
        ...PhasesDefaultContent[Phases.Brief],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/a3422429-4cec-4f92-8440-7007a2182ec5?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/2d126251-9d9a-4083-8356-360b13cba8c9?back-to-custom&name=no7beauty',
          },
        ],
      },
      [Phases.Direction]: {
        ...PhasesDefaultContent[Phases.Direction],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/64d7c819-137d-45e4-9a8e-36fc54fe2e9c?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/a844c1f8-5efa-4d73-a39c-c48d36f55516?back-to-custom&name=no7beauty',
          },
        ],
      },
      [Phases.Strategy]: {
        ...PhasesDefaultContent[Phases.Strategy],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/865ad66a-aa7a-4ed7-8b17-ba665a3ac0e1?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/47347e86-d3ed-45f7-8d20-f2277cc891ac?back-to-custom&name=no7beauty',
          },
        ],
      },
      [Phases.Idea]: {
        ...PhasesDefaultContent[Phases.Idea],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/ab334837-e3f9-403f-8687-e90866a32637?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/fa2eaae6-0559-4848-98af-7edc8eb7bc59?back-to-custom&name=no7beauty',
          },
        ],
      },
      [Phases.Experience]: {
        ...PhasesDefaultContent[Phases.Experience],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/fcf0fe13-9a25-4e25-910f-40901788bf72?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/b940f99a-70db-442d-bb01-c9e9f478f2b2?back-to-custom&name=no7beauty',
          },
        ],
      },
      [Phases.Delivery]: {
        ...PhasesDefaultContent[Phases.Delivery],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/fd0bbb25-e5c7-43de-8cba-7acfb9180f51?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/6a55cf23-7402-4b8f-be94-89f1cb713743?back-to-custom&name=no7beauty',
          },
        ],
      },
      [Phases.Impact]: {
        ...PhasesDefaultContent[Phases.Impact],
        buttons: [
          {
            title: 'Global Process',
            url: 'https://wba.os.wpp.com/process/f1e86015-838b-45a6-a380-b787541d7e07/phase/01dcff43-27ec-46d2-945f-ca0ed81a97ad?back-to-custom&name=no7beauty',
          },
          {
            title: 'Local Process',
            url: 'https://wba.os.wpp.com/process/8e1b2548-4870-46a1-8631-05baec0e0893/phase/9b57ed5f-7cab-4651-8172-2896522b0a12?back-to-custom&name=no7beauty',
          },
        ],
      },
    },
  },
  boots: {
    colors: {
      '--bg-top': '#FFFBDB',
      '--bg-left': '#E6EEFF',
      '--bg-right': '#FFE6E8',

      '--header-primary-color': '#001F61',
      '--header-secondary-color': '#F6E302',
      '--header-tertiary-color': '#C7B800',
      '--header-quaternary-color': '#00277A',
      '--header-quinary-color': '#013FC2',
      '--header-senary-color': '#013FC2',
      '--header-septenary-color': '#990011',
      '--header-octonary-color': '#E5283D',

      '--phase-index-color': '#000000',
      '--phase-index-active-color': '#1c1c1c',
      '--brief-bg-color': '#fffabb',
      '--brief-border-color': '#fefdf2',
      '--brief-border-active-color': '#f6e302',
      '--brief-bg-active-color': '#ffffff',
      '--strategy-bg-color': '#ebebeb',
      '--strategy-border-color': '#ffffff',
      '--strategy-bg-active-color': '#ffffff',
      '--strategy-border-active-color': '#3b3b3b',
      '--idea-bg-color': '#dce7ff',
      '--idea-border-color': '#f7f9fb',
      '--idea-bg-active-color': '#ffffff',
      '--idea-border-active-color': '#013fc2',
      '--design-bg-color': '#ffd8dd',
      '--design-border-color': '#fef4f5',
      '--design-bg-active-color': '#ffffff',
      '--design-border-active-color': '#e5283d',
    },
    logo: BootsLogo,
    animation: pharmArrowAnimation,
    phases: {
      [Phases.Brief]: {
        ...PhasesDefaultContent[Phases.Brief],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/a1666236-629c-41d3-aabc-a1be95171b23?back-to-custom&name=Boots',
          },
        ],
      },
      [Phases.Direction]: {
        ...PhasesDefaultContent[Phases.Direction],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/6ad129dc-9f14-45cd-99aa-a36e9884df86?back-to-custom&name=Boots',
          },
        ],
      },
      [Phases.Strategy]: {
        ...PhasesDefaultContent[Phases.Strategy],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/9af17cc2-f581-4722-9ce0-1fbd22201c20?back-to-custom&name=Boots',
          },
        ],
      },
      [Phases.Idea]: {
        ...PhasesDefaultContent[Phases.Idea],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/5f098f21-0853-432d-9291-f4214308850b?back-to-custom&name=Boots',
          },
        ],
      },
      [Phases.Experience]: {
        ...PhasesDefaultContent[Phases.Experience],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/c75044c8-5a73-4041-bc55-c5571edcc314?back-to-custom&name=Boots',
          },
        ],
      },
      [Phases.Delivery]: {
        ...PhasesDefaultContent[Phases.Delivery],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/f8a87841-2785-46d0-a7da-a918d2c9aae1?back-to-custom&name=Boots',
          },
        ],
      },
      [Phases.Impact]: {
        ...PhasesDefaultContent[Phases.Impact],
        buttons: [
          {
            title: 'See details',
            url: 'https://wba.os.wpp.com/process/68d4f5b0-24d5-4414-a8c1-463b867ab636/phase/687f1750-a63a-4658-9b0d-0b474624ab7d?back-to-custom&name=Boots',
          },
        ],
      },
    },
  },
}
